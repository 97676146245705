import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Form, Col } from "react-bootstrap";
import AdminLandingPage from "../../../LayoutAdmin/AdminLandingPage";
import API from "../../../API";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/joy/Snackbar';
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';


export default function TempoCheckIn() {
    const [tempoData, setTempoData] = useState();
    const [tempoImages, setTempoImages] = useState();

    const imageUrl = process.env.REACT_APP_SERVER_PROJPATH;
    const { http, saveToken } = API();
    const currentDate = new Date();
    let date = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    const [userData, setUserData] = useState();
    const [successMessage, setSuccessMessage] = useState(null);
    const [color, setColor] = React.useState('neutral');
    const [open, setOpen] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            id: 0,
            tempoid: 0,
            startReading: 0,
            endReading: 0,
            fromLocation: "",
            toLocation: "",
            totalReading: 0,
            files: [],
            diesel: 0,
            driverid: 0,
            distance:0,
        },
        validationSchema: Yup.object({
            tempoid: Yup.number()
                .positive('Please Select Tempo')
                .required('Please Select Tempo'),

            driverid: Yup.number()
                .positive('Please Select Tempo Driver Name')
                .required('Please Select Tempo Driver Name'),
        }),
        onSubmit: async (values, { resetForm }) => {

            let fd = new FormData();
            fd.append("tempoid", values.tempoid);
            fd.append("startreading", values.startReading);
            fd.append("endreading", (values.endReading));
            fd.append("fromlocation", (values.fromLocation));
            fd.append("tolocation", (values.toLocation));
            fd.append("totalreading", (values.totalReading));
            fd.append("count", (values.files.length));
            fd.append("date", date);
            fd.append("year", year);
            fd.append("month", month);
            fd.append("distance", values.distance);
            values.files.forEach(function (item, key) {
                fd.append("file" + (key + 1), (item));
            });
            fd.append("diesel", values.diesel);
            fd.append('driver_id', values.driverid);
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            if (values.id > 0) {
                fd.append('id', values.id);
                await http.post(`tempo/checkin/perday/update`, fd, config
                ).then((res) => {
                    if (res.data.status == "success") {
                        setSuccessMessage(res.data.message);
                        setColor("success")
                    } else {
                        setSuccessMessage(res.data.message);
                        setColor("warning")
                    }
                    setOpen(true)
                }).catch((error) => {
                    setSuccessMessage("Error Something is bad...");
                    setColor("danger")
                    setOpen(true)
                })

            } else {
                await http.post('tempo/checkin/perday', fd, config
                ).then((res) => {
                    if (res.data.status == "success") {
                        setSuccessMessage(res.data.message);
                        setColor("success")
                    } else {
                        setSuccessMessage(res.data.message);
                        setColor("warning")
                    }
                    setOpen(true)
                }).catch((error) => {
                    setSuccessMessage("Error Something is bad...");
                    setColor("danger")
                    setOpen(true)
                })

            }
            resetForm();
            setTempoImages();
        }
    });

    useEffect(() => {
        getTempoData();
        getUserData();
    }, [])

    useEffect(() => {

    }, [formik.values.files])

    useEffect(() => {
        if (formik.values.id) {
            getTempoImages(formik.values.id)
        }
    }, [formik.values.id])

    useEffect(() => {
        if (formik.values.tempoid > 0) {
            getTempoMonthlyData(date,month,year,formik.values.tempoid)
        }
    }, [formik.values.tempoid])

    useEffect(() => {
        formik.values.totalReading = formik.values.endReading - formik.values.startReading;
    }, [formik.values.endReading]);
    useEffect(() => {
        formik.values.totalReading = formik.values.endReading - formik.values.startReading;
    }, [formik.values.startReading]);
    useEffect(() => {
        if (formik.values.totalReading < 0) {
            formik.values.totalReading = 0;
        }
    }, [formik.values.totalReading]);
    return (
        <>
            <Container>
                <Row >

                    <Form onSubmit={formik.handleSubmit}>

                        <Col xs={12} md={8} lg={8} xl={8} className="shadow p-3 mb-5 bg-white rounded">
                            <div style={{ textAlign: "center", fontSize: "24px", backgroundColor: "#6699CC", color: "#fff", padding: "2px" }} ><b>Tempo Entry Form</b></div>
                            <div>
                                <hr />
                            </div>
                            <Form.Group controlId="tempoid">
                                <Form.Label>Tempo Name</Form.Label>
                                <select
                                    className="form-control"
                                    name="tempoid"
                                    value={formik.values.tempoid}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Select Tempo </option>
                                    {tempoData &&
                                        tempoData.map((index) => {
                                            return (
                                                <option value={index.id}>
                                                    {index.vehicleno}
                                                </option>
                                            );
                                        })}
                                </select>
                            </Form.Group>

                            <Form.Group controlId="driverid">

                                <Form.Label>Driver Name</Form.Label>
                                <select
                                    className="form-control"
                                    name="driverid"
                                    value={formik.values.driverid}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Select Tempo Driver</option>
                                    {userData &&
                                        userData.map((index) => {
                                            return (
                                                <option value={index.id}>
                                                    {index.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </Form.Group>

                            <Form.Group controlId="startReading">
                                <Form.Label>Start Reading</Form.Label>
                                <Form.Control
                                    name="startReading"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.startReading}
                                />
                            </Form.Group>

                            <Form.Group controlId="endReading">
                                <Form.Label>End Reading</Form.Label>
                                <Form.Control
                                    name="endReading"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.endReading}
                                />
                            </Form.Group>

                            <Form.Group controlId="totalReading">
                                <Form.Label>Total Reading</Form.Label>
                                <Form.Control
                                    name="totalReading"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.totalReading}
                                    disabled
                                />

                            </Form.Group>

                            <Form.Group controlId="fromLocation">
                                <Form.Label>From Location</Form.Label>
                                <Form.Control
                                    name="fromLocation"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.fromLocation}
                                />
                            </Form.Group>

                            <Form.Group controlId="toLocation">
                                <Form.Label>To Location</Form.Label>
                                <Form.Control
                                    name="toLocation"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.toLocation}
                                />

                            </Form.Group>

                            <Form.Group controlId="diesel">
                                <Form.Label>Diesel in Ltr</Form.Label>
                                <Form.Control
                                    name="diesel"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.diesel}
                                />
                            </Form.Group>

                            <Form.Group controlId="distance">
                                <Form.Label>Distance</Form.Label>
                                <Form.Control
                                    name="distance"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.distance}
                                />

                            </Form.Group>


                            {/* <div style={{ textAlign: "center", fontSize: "24px", backgroundColor: "#6699CC", color: "#fff", padding: "8px" }}><b>Tractor Entry Form</b></div> */}
                            <Form.Group controlId="files">
                                <Form.Label>Upload Multiple Files</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="files"
                                    onChange={(event) => {
                                        formik.setFieldValue('files', [...event.currentTarget.files]);
                                    }}
                                    multiple
                                />
                            </Form.Group>

                            {tempoImages && tempoImages.length > 0 && (
                                <div>
                                    <p>Selected Images:</p>
                                    {tempoImages.map((file, index) => (
                                        <div className="shadow p-3 mb-2 bg-white rounded">
                                            <img
                                                key={index}
                                                src={imageUrl + file.image}
                                                alt={`Image ${index + 1}`}
                                                style={{ width: '90%', maxHeight: '300px', marginRight: '10px' }}
                                            />
                                            <span style={{ fontSize: "32px" }} onClick={
                                                () => deleteImg(file.image, file, tempoImages)
                                            }>
                                                <DeleteIcon className="md-48" style={{ fontSize: "24px" }} />
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}


                            <div>
                                {tempoImages && tempoImages.length > 0 ? "" : <p>Selected Images:</p>}
                                {formik.values.files && formik.values.files.map((file, index) => (
                                    <div className="shadow p-3 mb-2 bg-white rounded">
                                        <img
                                            key={index}
                                            src={URL.createObjectURL(file)}
                                            alt={`Image ${index + 1}`}
                                            style={{ width: '90%', maxHeight: '300px', marginRight: '10px' }}
                                        />
                                        <span style={{ fontSize: "32px" }} onClick={
                                            () => deleteImgFromFile(file, formik.values.files)
                                        }>
                                            <DeleteIcon className="md-48" style={{ fontSize: "24px" }} />
                                        </span>
                                    </div>

                                ))}
                            </div>


                            <br />
                            <Stack spacing={2} direction="row" justifyContent="center" >
                                <Button variant="contained"
                                    type="submit"
                                // onClick={values => setFormState(values)}
                                >Submit</Button>
                            </Stack>
                        </Col>

                    </Form>
                </Row>
            </Container>

            <Container>
                <Stack spacing={1} alignItems="center" >
                    <Snackbar
                        className='col-6'
                        autoHideDuration={4000}
                        open={open}
                        variant={'outlined'}
                        color={color}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        {successMessage}
                    </Snackbar>
                </Stack>
            </Container>

        </>
    );
    async function deleteImg(img, file, tempoImages) {

        await http.post('tempo/checkin/images',
            {
                imageName: img,
            }
        ).then((res) => {
            if (res.data.status == "success") {

                setSuccessMessage(res.data.message);
                let updatedData = tempoImages.filter((item, key) => {
                    if (item.id !== file.id) {
                        return item;
                    }
                })
                setTempoImages(updatedData)
                setColor("success")

            } else {
                setSuccessMessage(res.data.message);
                setColor("warning")
            }
            setOpen(true)
        }).catch((error) => {
            setSuccessMessage('Image not found');
            setColor("danger")
            setOpen(true)
        })
    }

    async function deleteImgFromFile(file, tempoImages) {

        setSuccessMessage("Image deleted successfully");
        let updatedData = tempoImages.filter((item, key) => {
            if (item.name !== file.name) {
                return item;
            }
        })
        if (updatedData.length === 0) {
            formik.setFieldValue('files', []);
        } else {
            formik.setFieldValue('files', updatedData);
        }
        setColor("success")
    }

    async function getTempoData() {
        await http.get('tempomaster').then((res) => {
            if (res.data.status == "success") {
                setTempoData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }
    async function getUserData() {
        await http.get('user/role', {
            params: {
                role: 8
            }
        }).then((res) => {
            if (res.data.status == "success") {
                setUserData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }

    async function getTempoImages(id){
        await http.get('tempo_image/checkin/id', {
            params: {
                id: id,
            },
        }).then((res) => {
            if(res.data.status=="success"){
                setTempoImages(JSON.parse(res.data.data));
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }

    async function getTempoMonthlyData(date,month,year,tempoid){
        await http.get('tempo/checkin/perday', {
            params: {
                date: date, month: month, year: year, tempoid: tempoid
            }
        }).then((res) => {
            if (res.data.status == "success") {
                if (res.data.message !== "No Tempo Data Found...") {
                    let data = JSON.parse(res.data.data);
                    formik.setFieldValue('totalReading', data?.totalreading);
                    formik.setFieldValue('startReading', data?.startreading);
                    formik.setFieldValue('endReading', data?.endreading);
                    formik.setFieldValue('fromLocation', data?.fromlocation);

                    formik.setFieldValue('toLocation', data?.tolocation);
                    formik.setFieldValue('id', data?.id);
                    formik.setFieldValue('driverid', data?.driver_id);
                    formik.setFieldValue('diesel', data?.diesel);
                    formik.setFieldValue('distance', data?.distance);
                    formik.values.files = [];
                    console.log(data)
                } else {
                    formik.setFieldValue('totalReading', 0);
                    formik.setFieldValue('startReading', 0);
                    formik.setFieldValue('endReading', 0);
                    formik.setFieldValue('fromLocation', "");
                    formik.setFieldValue('distance', 0);
                    formik.setFieldValue('toLocation', "");
                    formik.setFieldValue('id', 0);
                    formik.setFieldValue('driverid', 0);
                    formik.setFieldValue('diesel', 0);
                    formik.values.files = [];
                }
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }


}


