import logo from './logo.svg';
import './App.css';
import { Login } from './Components/Global/Login';
import AdminLandingPage from './LayoutAdmin/AdminLandingPage';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import TractorMenu from './Components/Admin/TractorMaster/TractorMenu';
import TankerMenu from './Components/Admin/TankerMaster/TankerMenu';
import BroomerMenu from './Components/Admin/BroomerMaster/BroomerMenu';
import JCBMenu from './Components/Admin/JCBMaster/JCBMenu';
import TempoMenu from './Components/Admin/TempoMaster/TempoMenu';
import TipperMenu from './Components/Admin/TipperMaster/TipperMenu';
import Registration from './Components/Admin/Registration';
import EmployeeMenu from './Components/Admin/EmployeeMaster/EmployeeMenu';


function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/admin/tractor" element={<TractorMenu/>}/>
                <Route path="/admin/tanker" element={<TankerMenu/>}/>
                <Route path="/admin" element={<AdminLandingPage/>}/>     
                <Route path="/admin/broomer" element={<BroomerMenu/>}/>
                <Route path="/admin/tempo" element={<TempoMenu/>}/>
                <Route path="/admin/tipper" element={<TipperMenu/>}/>
                <Route path="/admin/jcb" element={<JCBMenu/>}/>
                <Route path="/admin/employee" element={<EmployeeMenu/>}/>
                <Route path="/admin/registration" element={<Registration/>}/>  
                {/* <Route path="/admin/changepassword" element={<ChangePassword/>}/> */}
      {/* <Login/> */}
      
      </Routes>
      </Router>
      {/* <AdminLandingPage/> */}
    </div>
  );
}

export default App;
