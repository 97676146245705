import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import API from "../../API";
import { Container, Stack } from "react-bootstrap";
import { Snackbar } from "@mui/joy";

export const Login = () => {
  const { http,saveToken } = API();
 const navigate=useNavigate();

  const rememberMe = '';
  let myInitialValues = {
    username: rememberMe ? (localStorage.getItem("username")) : "",
    password: rememberMe ? (localStorage.getItem("password")) : "",
  };
  const [type, setType] = useState("password");
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [color, setColor] = React.useState('neutral');
  const [open, setOpen] = React.useState(false);

  return (
    <Formik
      initialValues={myInitialValues}
      onSubmit={async (values, actions) => {
        await http.post('login', {
          email: values.username, password: values.password
        }).then((res) => {
          // console.log(res.data)
          saveToken(res.data.user,res.data.access_token)
          setSuccessMessage('User Logged In Successfully ');
          setColor("success")
          setOpen(true)
          navigate('/admin');
        }).catch((error) => {
          setSuccessMessage('Incorrect User or Password');
          setColor("danger")
          setOpen(true)
        })

      }}
      validationSchema={Yup.object({
        username: Yup.string().required("Username is Required"),
        password: Yup.string().required("Password is Required"),
      })}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <div className="login-register pt-3 py-3 row m-0 justify-content-center">
          <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-11">
            <div className="card mx-auto border-0 my-5">
              <div className="card-header border-bottom-0 bg-transparent pt-4">
                <h5 className="text-center p-2">
                  Please Sign In
                </h5>
                <ul
                  className="nav nav-tab pt-2 justify-content-center text-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    {/* <Link
                        className="nav-link active"
                        id="pills-login-tab"
                        data-toggle="pill"
                        to="/properties/users/login"
                        role="tab"
                        aria-controls="pills-login"
                        aria-selected="true"
                      >
                        Sign In
                      </Link> */}
                  </li>
                  <li className="nav-item">
                    {/* <Link
                        className="nav-link"
                        id="pills-register-tab"
                        data-toggle="pill"
                        to="/properties/users/register"
                        role="tab"
                        aria-controls="pills-register"
                        aria-selected="false"
                      >
                        Register
                      </Link> */}
                  </li>
                </ul>
              </div>
              <div className="card-body pb-5">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-login"
                    role="tabpanel"
                    aria-labelledby="pills-login-tab"
                  >
                    <Form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <Field
                          name="username"
                          as="input"
                          type="text"
                          placeholder="Email address"
                          values={values.username}
                          className="form-control"
                        />
                        <ErrorMessage name="username">
                          {(msg) => (
                            <div className="alert alert-danger">{msg}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="form-group">
                        <Field
                          name="password"
                          as="input"
                          type={type}
                          placeholder="Password"
                          values={values.password}
                          className="form-control"
                        />
                        {type === "password" ? (
                          <i
                            className="feather icon-eye"
                            onClick={() => {
                              setType("text");
                            }}
                          />
                        ) : (
                          <i
                            className="feather icon-eye-off"
                            onClick={() => {
                              setType("password");
                            }}
                          />
                        )}
                        <ErrorMessage name="password">
                          {(msg) => (
                            <div className="alert alert-danger">{msg}</div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="custom-control custom-checkbox py-2">
                        <Field
                          type="checkbox"
                          className="custom-control-input"
                          id="rememberme"
                          name="rememberme"
                          checked={rememberMe}
                          values={rememberMe}
                          onClick={() => {
                            if (rememberMe) {
                              console.log("HEllo")
                              
                            } else {
                            
                            }
                          }}
                        />
                        <label
                          className="custom-control-label font-10"
                          htmlFor="rememberme"
                        >
                          Keep me logged in on this computer
                        </label>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-success btn-lg btn-block font-18"
                          disabled={isSubmitting}
                        >
                          Sign In
                        </button>
                      </div>
                      
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Container>
                <Stack spacing={1} alignItems="center" >
                    <Snackbar
                        className='col-6'
                        autoHideDuration={4000}
                        open={open}
                        variant={'outlined'}
                        color={color}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        {successMessage}
                    </Snackbar>
                </Stack>
            </Container>
        </div>
      )}
    </Formik>

  );
}
// export default Login;