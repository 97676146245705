import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import API from '../../../API';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Container } from 'react-bootstrap';
import { Snackbar } from '@mui/joy';
import EmployeeSearchModule from './EmployeeModuleSearch';


export default function EmployeeDataList() {
    const { http, saveToken } = API();
    const [tankerData, setTankerData] = useState();
    const [tankerImages, setTankerImages] = useState();
    const [userData, setUserData] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    const [tankerId, setTankerId] = useState();
    const [locationId, setLocationId] = useState();
    const componentRef = useRef();
    const componentdailyRef = useRef();
    const [tankerMonthlyData, setTankerMonthlyData] = useState();
    const [tankerDailyData, setTankerDailyData] = useState();
    const [successMessage, setSuccessMessage] = useState(null);
    const [color, setColor] = React.useState('neutral');
    const [open, setOpen] = React.useState(false);

    const [tendorLocationData, setTendorLocationData] = useState()

    useEffect(() => {
        getUserRole();
    }, [])

    useEffect(() => {
        if (locationId && year && month) {
            // getDailyMonthlyData()
            getUserDailyData()
        }
    }, [locationId, year, month])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrint1 = useReactToPrint({
        content: () => componentdailyRef.current,
    });


    useEffect(() => {
        if (tankerDailyData && tankerImages) {
            handlePrint1();
        }
    }, [tankerDailyData, tankerImages])


    return (
        <>
            {
                tendorLocationData &&
                <EmployeeSearchModule setLocationId={setLocationId} setMonth={setMonth} setYear={setYear} locationData={tendorLocationData} />

            }
            <br />
            <TableContainer component={Paper}>

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Sr No</b></TableCell>
                            <TableCell><b>Employee Id</b></TableCell>
                            <TableCell><b>Name</b></TableCell>
                            <TableCell><b>Position</b></TableCell>
                            <TableCell><b>Action</b></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userData && userData?.data?.map((row, key) => (

                            <TableRow
                                key={key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {key + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.id}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.emp_role}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                {row?.checkin==1?<span style={{background:"light-green",padding:"8px"}} className='bg-success text-white'>Present</span>:
                                <span style={{background:"success",padding:"8px"}} className='bg-warning text-white'>Absent</span>}
                                  
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {tankerMonthlyData && <>
                <Stack spacing={2} direction="row" justifyContent="center" >
                    <Button variant="contained"
                        onClick={(e) => {
                            e.preventDefault();
                            handlePrint();
                        }
                        }
                    >Print</Button>
                </Stack>
                {/* <MonthlyPrint tankerMonthlyData={tankerMonthlyData} ref={componentRef} /> */}
            </>}


            {/* {
                tankerImages &&
                <DailyPrint
                    tankerDailyData={tankerDailyData}
                    ref={componentdailyRef}
                    tankerImages={tankerImages}
                    tankerData={tankerData}
                    userData={userData}
                />
            } */}
            <Container>
                <Stack spacing={1} alignItems="center" >
                    <Snackbar
                        className='col-6'
                        autoHideDuration={4000}
                        open={open}
                        variant={'outlined'}
                        color={color}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        {successMessage}
                    </Snackbar>
                </Stack>
            </Container>
        </>
    );

    async function getImagesById(data) {
        setTankerDailyData(data)
        await http.get('tanker_image/checkin/id', {
            params: {
                id: data.id,
            },
        }).then((res) => {
            if (res.data.status == "success") {
                setTankerImages(JSON.parse(res.data.data));
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });

    }

    async function getUserDailyData() {
        const currentDate = new Date();
        let date = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        await http.get('empcheckin', {
            params: {
                date: date,
                month: month,
                year: year,
                location_id: locationId
            }
        }).then((res) => {
            if (res.data.status == "success") {

                setUserData(JSON.parse(res.data.data))
                setSuccessMessage(res.data.message);
                setColor("success")
                setOpen(true)
            }
        }).catch((error) => {
            setSuccessMessage('Error Something is bad...');
            setColor("danger")
            setOpen(true)
        });
    }





    async function getUserRole() {
        await http.get('tender').then((res) => {
            if (res.data.status == "success") {
                setTendorLocationData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage('Error Something is bad...');
            setColor("danger")
            setOpen(true)
        });
    }

}