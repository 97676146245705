import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import API from '../../../API';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import MonthlyPrint from './MonthlyPrint';
import DailyPrint from './DailyPrint';
import TempoSearchModule from './TempoSearchModule';
import { Container } from 'react-bootstrap';
import { Snackbar } from '@mui/joy';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function TempoDataList() {
    const { http, saveToken } = API();
    const [tempoData, setTempoData] = useState();
    const [tempoImages, setTempoImages] = useState();
    const [userData, setUserData] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    const [tempoId, setTempoId] = useState();
    const componentRef = useRef();
    const componentdailyRef = useRef();
    const [tempoMonthlyData, setTempoMonthlyData] = useState();
    const [tempoDailyData,setTempoDailyData]=useState();
    const [successMessage, setSuccessMessage] = useState(null);
    const [color, setColor] = React.useState('neutral');
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        getTempoData();
       getUserData()
    }, []);

    useEffect(() => {
        if (tempoId && year && month) {
            getTempoMonthlyData()
        }
    }, [tempoId, year, month])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    async function getImagesById(data){
        setTempoDailyData(data)
        await http.get('tempo_image/checkin/id', {
                params: {
                    id: data.id,
                },
            }).then((res) => {
                setTempoImages(JSON.parse(res.data.data));
            }).catch((error) => {
                console.log(error)
            });
        
    } 

    const handlePrint1 = useReactToPrint({
        content: () => componentdailyRef.current,
    });


    useEffect(()=>{
        if(tempoDailyData && tempoImages){
            handlePrint1();
        }
    },[tempoDailyData,tempoImages])


    return (
        <>
            <TempoSearchModule tempoData={tempoData} setTempoId={setTempoId} setMonth={setMonth} setYear={setYear} />
            <br />
            <TableContainer component={Paper}>

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Id</b></TableCell>
                            <TableCell><b>Date</b></TableCell>
                            <TableCell><b>Start Reading</b></TableCell>
                            <TableCell><b>End Reading</b></TableCell>
                            <TableCell><b>Total Reading</b></TableCell>
                            <TableCell><b>From Location</b></TableCell>
                            <TableCell align="right"><b>To Location</b></TableCell>
                            <TableCell align="right"><b>Diesel</b></TableCell>
                            <TableCell align="right"><b>Distance</b></TableCell>
                            <TableCell align="right"><b>Holiday</b></TableCell>
                            <TableCell align="right"><b>Download</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tempoMonthlyData && tempoMonthlyData.map((row, key) => (
                            <TableRow
                                key={key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {key + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.date}-{row?.month}-{row?.year}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.startreading}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.endreading}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.totalreading}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.fromlocation}
                                </TableCell>
                                <TableCell align="right">{row?.tolocation}</TableCell>
                                <TableCell align="right">{row?.diesel}</TableCell>
                                <TableCell align="right">{row?.distance}</TableCell>
                                <TableCell align="right">{row?.holiday == 1 ? "YES" : "NO"}</TableCell>

                                <TableCell align="right">
                                    <Button variant="contained"
                                       onClick={(e) => {
                                        e.preventDefault();
                                        getImagesById(row);
                                    }
                                    }
                                    >Print</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {tempoMonthlyData && <>
                <Stack spacing={2} direction="row" justifyContent="center" >
                    <Button variant="contained"
                        onClick={(e) => {
                            e.preventDefault();
                            handlePrint();
                        }
                        }
                    >Print</Button>
                </Stack>
                <MonthlyPrint tempoMonthlyData={tempoMonthlyData} ref={componentRef} />
            </>}


            {
                tempoImages&&
                <DailyPrint
                tempoDailyData={tempoDailyData}
                ref={componentdailyRef}
                tempoImages={tempoImages}
                tempoData={tempoData}
                userData={userData}
                />
            }
             <Container>
                <Stack spacing={1} alignItems="center" >
                    <Snackbar
                        className='col-6'
                        autoHideDuration={4000}
                        open={open}
                        variant={'outlined'}
                        color={color}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        {successMessage}
                    </Snackbar>
                </Stack>
            </Container>
        </>
    );

    async function getTempoData(){
        await  http.get('tempomaster').then((res) => {
            if(res.data.status=="success"){
                setTempoData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }

    async function getUserData(){
        await  http.get('user/role', {
            params: {
                role: 8
            }
        }).then((res) => {
            if(res.data.status=="success"){
                setUserData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }

    async function getTempoMonthlyData(){
        await  http.get('tempo/checkin/monthly', {
            params: {
                tempoid: tempoId, year: year, month: month
            }
        }).then((res) => {
            if(res.data.status=="success"){

            }
            setTempoMonthlyData(JSON.parse(res.data.data))
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }

}