import * as React from 'react';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { Button, Paper } from '@mui/material';
import Snackbar from '@mui/joy/Snackbar';
import API from '../../../API';
import EmployeeCheckForm from './EmployeeCheckForm';
import { Container } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';

export default function EmployeeCheckIn() {
    const { http, saveToken } = API();
    const [page, setPage] = React.useState();
    const [count, setCount] = React.useState();
    const [successMessage, setSuccessMessage] = React.useState(null);
    const [color, setColor] = React.useState('neutral');
    const [open, setOpen] = React.useState(false);
    const [location_id, setLocation_id] = React.useState();
    const [userData, setUserData] = useState();
    const [tendorLocationData, setTendorLocationData] = React.useState()
    const handleChange = (event, value) => {
        setPage(value);
    };
    React.useEffect(() => {
        getUserRole();
    }, [])

    React.useEffect(() => {
        if (location_id > 0) {
            getAllUser(1, location_id);
        }
    }, [location_id])
    useEffect(() => {

    }, [userData])



    console.log(userData?.data, count)
    return (
        <>
            <EmployeeCheckForm locationData={tendorLocationData} setLocation_id={setLocation_id} />

            <TableContainer component={Paper}>

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Sr No</b></TableCell>
                            <TableCell><b>Employee Id</b></TableCell>
                            <TableCell><b>Name</b></TableCell>
                            <TableCell><b>Position</b></TableCell>
                            <TableCell><b>Action</b></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userData && userData?.data?.map((row, key) => (

                            <TableRow
                                key={key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {key + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.id}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.emp_role}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                    <Stack spacing={2} direction="row" justifyContent="center" >
                                        <Button variant="contained"
                                            color="success" onClick={(e) => {
                                                e.preventDefault();
                                                DailyCheckedInPresent(row);
                                            }
                                            }
                                        >Present</Button>
                                    </Stack>
                                    <br />
                                    <Stack spacing={2} direction="row" justifyContent="center" >
                                        <Button variant="contained"
                                            color="warning" onClick={(e) => {
                                                e.preventDefault();
                                                DailyCheckedInAbsent(row);
                                            }
                                            }
                                        >Absent</Button>
                                    </Stack>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Container>
                <Stack spacing={1} alignItems="center" >
                    <Snackbar
                        className='col-6'
                        autoHideDuration={4000}
                        open={open}
                        variant={'outlined'}
                        color={color}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        {successMessage}
                    </Snackbar>
                </Stack>
            </Container>

            {
                userData ?
                    <Stack spacing={1}>
                        <Typography>Page: {page}</Typography>
                        <Pagination count={count} page={page} onChange={handleChange} />
                    </Stack> : ""
            }



        </>

    );

    async function DailyCheckedInPresent(data) {
       
        const currentDate = new Date();
        let date = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        await http.post('empcheckin', {
             
                checkin: 1,
                date: date,
                month: month,
                year: year,
                user_id: data?.id,
            
        })
            .then((res) => {
                if (res.data.status == "success") {
                    setSuccessMessage(res.data.message);
                    setColor("success")
                    setOpen(true)
                }
            }).catch((error) => {
                setSuccessMessage('Error Something is bad...');
                setColor("danger")
                setOpen(true)

            });
    }
    async function DailyCheckedInAbsent(data) {

        const currentDate = new Date();
        let date = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        await http.post('empcheckin', {
          
                checkin: 1,
                date: date,
                month: month,
                year: year,
                user_id: data?.id,
           
        })
            .then((res) => {
                if (res.data.status == "success") {
                    setSuccessMessage(res.data.message);
                    setColor("success")
                    setOpen(true)
                }
            }).catch((error) => {
                setSuccessMessage('Error Something is bad...');
                setColor("danger")
                setOpen(true)

            });
    }

    async function getAllUser(page, location_id) {
        await http.get('user', {
            params: {
                location_id: location_id
            }
        })
            .then((res) => {
                if (res.data.status == "success") {
                    console.log(JSON.parse(res.data.data))
                    let data = JSON.parse(res.data.data)
                    setUserData(JSON.parse(res.data.data))
                    setSuccessMessage(res.data.message);
                    setColor("success")
                    setCount((Math.ceil(data?.total / data?.per_page)))
                    setPage((data?.current_page))
                    setOpen(true)
                }
            }).catch((error) => {
                setSuccessMessage('Error Something is bad...');
                setColor("danger")
                setOpen(true)

            });
    }

    async function getUserRole() {
        await http.get('tender').then((res) => {
            if (res.data.status == "success") {
                setTendorLocationData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage('Error Something is bad...');
            setColor("danger")
            setOpen(true)
        });
    }

}