import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Form, Col } from "react-bootstrap";
import Stack from '@mui/material/Stack';
import { Button } from "@mui/material";

export default function EmployeeCheckForm({ setLocation_id, locationData
}) {
    const formik = useFormik({
        initialValues: {
            location_id: 0,
        },
        validationSchema: Yup.object({
            location_id: Yup.number()
                .positive('Please Select Location')
                .required('Please Select Location'),
        }),
        onSubmit: async (values) => {
            setLocation_id(values.location_id)
        }
    });

    return (
        <>
            <Container>
                <Row >
                    <Form onSubmit={formik.handleSubmit}>
                        <Col xs={12} md={8} lg={8} xl={8} className="shadow p-3 mb-5 bg-white rounded">
                            <div style={{ textAlign: "center", fontSize: "24px", backgroundColor: "#6699CC", color: "#fff", padding: "2px" }} ><b>Employee CheckIn Form</b></div>
                            <Form.Group controlId="location_id">
                                <Form.Label>Location Name</Form.Label>
                                <select
                                    className="form-control"
                                    name="location_id"
                                    value={formik.values.location_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Select Location</option>
                                    {locationData &&
                                        locationData.map((index) => {
                                            return (
                                                <option value={index.id}>
                                                    {index.name}
                                                </option>
                                            );
                                        })}
                                </select>

                                {formik.touched.location_id && formik.errors.location_id && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.location_id}</div>
                                )}
                            </Form.Group>

                            <br />
                            <Stack spacing={2} direction="row" justifyContent="center" >
                                <Button variant="contained"
                                    type="submit"
                                >Submit</Button>
                            </Stack>
                        </Col>
                    </Form>
                </Row>
            </Container>
        </>
    );
}


