import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Form, Col } from "react-bootstrap";
import API from "../../../API";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/joy/Snackbar';
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

 
export default function BroomerCheckIn() {
    const [broomerData, setBroomerData] = useState();
    const [broomerImages, setBroomerImages] = useState();

    const imageUrl = process.env.REACT_APP_SERVER_PROJPATH;
    const { http, saveToken } = API();
    const currentDate = new Date();
    let date = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    const [userData, setUserData] = useState();
    const [successMessage, setSuccessMessage] = useState(null);
    const [color, setColor] = React.useState('neutral');
    const [open, setOpen] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            id: 0,
            broomerid: 0,
            startReading: 0,
            endReading: 0,
            fromLocation: "",
            toLocation: "",
            totalReading: 0,
            files: [],
            diesel: 0,
            driverid: 0,
            distance: 0,
        },
        validationSchema: Yup.object({
            broomerid: Yup.number()
            .positive('Please Select Broomer Dumping')
            .required('Please Select Broomer Dumping'),

            driverid: Yup.number()
            .positive('Please Select Tanker Driver Name')
            .required('Please Select Tanker Driver Name'),
        }),
        onSubmit: async (values, { resetForm }) => {
          
            let fd = new FormData();
            fd.append("broomerid", values.broomerid);
            fd.append("startreading", values.startReading);
            fd.append("endreading", (values.endReading));
            fd.append("fromlocation", (values.fromLocation));
            fd.append("tolocation", (values.toLocation));
            fd.append("totalreading", (values.totalReading));
            fd.append("distance", (values.distance));
            fd.append("count", (values.files.length));
            fd.append("date", date);
            fd.append("year", year);
            fd.append("month", month);
            values.files.forEach(function (item, key) {
                fd.append("file" + (key + 1), (item));
            });
            fd.append("diesel", values.diesel);
            fd.append('driver_id', values.driverid);
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
          
            if (values.id > 0) {
                fd.append('id', values.id);
                await http.post(`broomer/checkin/perday/update`, fd, config
                ).then((res) => {
                    if (res.data.status == "success") {
                        setSuccessMessage(res.data.message);
                        setColor("success")
                    } else {
                        setSuccessMessage(res.data.message);
                        setColor("warning")
                    }
                    setOpen(true)
                }).catch((error) => {
                    setSuccessMessage("Error Something is bad...");
                    setColor("danger")
                    setOpen(true)
                })

            } else {
                await http.post('broomer/checkin/perday', fd, config
                ).then((res) => {
                    if (res.data.status == "success") {
                        setSuccessMessage(res.data.message);
                        setColor("success")
                    } else {
                        setSuccessMessage(res.data.message);
                        setColor("warning")
                    }
                    setOpen(true)
                }).catch((error) => {
                    setSuccessMessage("Error Something is bad...");
                    setColor("danger")
                    setOpen(true)
                })

            }
            resetForm();
            setBroomerImages();
        }
    });

    useEffect(() => {
        getBroomerData();
        getUserData();
    }, [])

    useEffect(() => {

    }, [formik.values.files])

    useEffect(() => {
        if (formik.values.id) {
          getImageData(formik.values.id)
        }
    }, [formik.values.id])

    useEffect(() => {
        if (formik.values.broomerid > 0) {
           getBroomerPerDayData(date,month,year,formik.values.broomerid)
        }
    }, [formik.values.broomerid])

    useEffect(() => {
        formik.values.totalReading = formik.values.endReading - formik.values.startReading;
    }, [formik.values.endReading]);
    useEffect(() => {
        formik.values.totalReading = formik.values.endReading - formik.values.startReading;
    }, [formik.values.startReading]);
    useEffect(() => {
        if (formik.values.totalReading < 0) {
            formik.values.totalReading = 0;
        }
    }, [formik.values.totalReading]);
    return (
        <>
            <Container>
                <Row >

                    <Form onSubmit={formik.handleSubmit}>

                        <Col xs={12} md={8} lg={8} xl={8} className="shadow p-3 mb-5 bg-white rounded">
                            <div style={{ textAlign: "center", fontSize: "24px", backgroundColor: "#6699CC", color: "#fff", padding: "2px" }} ><b>Broomer Entry Form</b></div>
                            <div>
                                <hr />
                            </div>
                            <Form.Group controlId="broomerid">
                                <Form.Label>Broomer Name</Form.Label>
                                <select
                                    className="form-control"
                                    name="broomerid"
                                    value={formik.values.broomerid}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Select Broomer</option>
                                    {broomerData &&
                                        broomerData.map((index,key) => {
                                            return (
                                                <option value={index.id} key={key}>
                                                    {index.vehicleno}
                                                </option>
                                            );
                                        })}
                                </select>
                            </Form.Group>

                            <Form.Group controlId="driverid">

                                <Form.Label>Driver Name</Form.Label>
                                <select
                                    className="form-control"
                                    name="driverid"
                                    value={formik.values.driverid}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Select Broomer Driver</option>
                                    {userData &&
                                        userData.map((index,key) => {
                                            return (
                                                <option value={index.id} key={key}>
                                                    {index.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </Form.Group>

                            <Form.Group controlId="startReading">
                                <Form.Label>Start Reading</Form.Label>
                                <Form.Control
                                    name="startReading"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.startReading}
                                />
                            </Form.Group>

                            <Form.Group controlId="endReading">
                                <Form.Label>End Reading</Form.Label>
                                <Form.Control
                                    name="endReading"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.endReading}
                                />
                            </Form.Group>

                            <Form.Group controlId="totalReading">
                                <Form.Label>Total Reading</Form.Label>
                                <Form.Control
                                    name="totalReading"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.totalReading}
                                    disabled
                                />

                            </Form.Group>

                            <Form.Group controlId="fromLocation">
                                <Form.Label>From Location</Form.Label>
                                <Form.Control
                                    name="fromLocation"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.fromLocation}
                                />
                            </Form.Group>

                            <Form.Group controlId="toLocation">
                                <Form.Label>To Location</Form.Label>
                                <Form.Control
                                    name="toLocation"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.toLocation}
                                />

                            </Form.Group>

                            <Form.Group controlId="diesel">
                                <Form.Label>Diesel in Ltr</Form.Label>
                                <Form.Control
                                    name="diesel"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.diesel}
                                />
                            </Form.Group>

                            <Form.Group controlId="distance">
                                <Form.Label>Distance</Form.Label>
                                <Form.Control
                                    name="distance"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.distance}
                                />

                            </Form.Group>

                            {/* <div style={{ textAlign: "center", fontSize: "24px", backgroundColor: "#6699CC", color: "#fff", padding: "8px" }}><b>Tractor Entry Form</b></div> */}
                            <Form.Group controlId="files">
                                <Form.Label>Upload Multiple Files</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="files"
                                    onChange={(event) => {
                                        formik.setFieldValue('files', [...event.currentTarget.files]);
                                    }}
                                    multiple
                                />
                            </Form.Group>

                            {broomerImages && broomerImages.length > 0 && (
                                <div>
                                    <p>Selected Images:</p>
                                    {broomerImages.map((file, index) => (
                                        <div className="shadow p-3 mb-2 bg-white rounded">
                                            <img
                                                key={index}
                                                src={imageUrl + file.image}
                                                alt={`Image ${index + 1}`}
                                                style={{ width: '90%', maxHeight: '300px', marginRight: '10px' }}
                                            />
                                            <span style={{ fontSize: "32px" }} onClick={
                                                () => deleteImg(file.image, file, broomerImages)
                                            }>
                                                <DeleteIcon className="md-48" style={{ fontSize: "24px" }} />
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}


                            <div>
                                {broomerImages && broomerImages.length > 0 ? "" : <p>Selected Images:</p>}
                                {formik.values.files && formik.values.files.map((file, index) => (
                                    <div className="shadow p-3 mb-2 bg-white rounded">
                                        <img
                                            key={index}
                                            src={URL.createObjectURL(file)}
                                            alt={`Image ${index + 1}`}
                                            style={{ width: '90%', maxHeight: '300px', marginRight: '10px' }}
                                        />
                                        <span style={{ fontSize: "32px" }} onClick={
                                            () => deleteImgFromFile(file, formik.values.files)
                                        }>
                                            <DeleteIcon className="md-48" style={{ fontSize: "24px" }} />
                                        </span>
                                    </div>

                                ))}
                            </div>


                            <br />
                            <Stack spacing={2} direction="row" justifyContent="center" >
                                <Button variant="contained"
                                    type="submit"
                                >Submit</Button>
                            </Stack>
                        </Col>

                    </Form>
                </Row>
            </Container>

            <Container>
                <Stack spacing={1} alignItems="center" >
                    <Snackbar
                        className='col-6'
                        autoHideDuration={4000}
                        open={open}
                        variant={'outlined'}
                        color={color}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        {successMessage}
                    </Snackbar>
                </Stack>
            </Container>

        </>
    );
    async function deleteImg(img, file, broomerImages) {

        await http.post('tanker/checkin/images',
            {
                imageName: img,
            }
        ).then((res) => {
            if (res.data.status == "success") {
                setSuccessMessage(res.data.message);
                let updatedData = broomerImages.filter((item, key) => {
                    if (item.id !== file.id) {
                        return item;
                    }
                })
                setBroomerImages(updatedData)
                setColor("success")

            } else {
                setSuccessMessage(res.data.message);
                setColor("warning")
            }
            setOpen(true)
        }).catch((error) => {
            setSuccessMessage('Image not found');
            setColor("danger")
            setOpen(true)
        })
    }

    async function getBroomerData(){
        await http.get('broomermaster').then((res) => {
            if(res.data.status=="success"){
                setBroomerData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage('Error Something is bad...');
            setColor("danger")
            setOpen(true)
        });
    }

    async function getUserData(){
        await http.get('user/role', {
            params: {
                role: 2
            }
        }).then((res) => {
            if(res.data.status=="success"){
                setUserData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage('Error Something is bad...');
            setColor("danger")
            setOpen(true)
        });
    }

    async function getImageData(id){
        await http.get('broomer_image/checkin/id', {
            params: {
                id: id,
            },
        }).then((res) => {
            if(res.data.status=="success"){
                setBroomerImages(JSON.parse(res.data.data));
            }
        }).catch((error) => {
            setSuccessMessage('Error Something is bad...');
            setColor("danger")
            setOpen(true)
        });
    }

    async function getBroomerPerDayData(date,month,year,broomerid){
        await  http.get('broomer/checkin/perday', {
            params: {
                date: date, month: month, year: year, broomerid:broomerid
            }
        }).then((res) => {
            if (res.data.status == "success") {
                if (res.data.message !== "No Broomer Data Found...") {
                    let data = JSON.parse(res.data.data);
                    formik.setFieldValue('totalReading',data?.totalreading);
                    formik.setFieldValue('startReading',data?.startreading);
                    formik.setFieldValue('endReading',data?.endreading);
                    formik.setFieldValue('fromLocation',data?.fromlocation);

                    formik.setFieldValue('toLocation',data?.tolocation);
                    formik.setFieldValue('id',data?.id);
                    formik.setFieldValue('distance',data?.distance);
                    formik.setFieldValue('driverid',data?.driver_id);
                    formik.setFieldValue('diesel',data?.diesel);
                    formik.values.files = [];
                    console.log(data)
                } else {
                    formik.setFieldValue('totalReading',0);
                    formik.setFieldValue('startReading',0);
                    formik.setFieldValue('endReading',0);
                    formik.setFieldValue('fromLocation',"");
                    formik.setFieldValue('distance',0);
                    formik.setFieldValue('toLocation',"");
                    formik.setFieldValue('id',0);
                    formik.setFieldValue('driverid',0);
                    formik.setFieldValue('diesel',0);
                    formik.values.files = [];
                }
            }
        }).catch((error) => {
            setSuccessMessage('Error Something is bad...');
            setColor("danger")
            setOpen(true)
        
        });
    }

    async function deleteImgFromFile(file, broomerImages) {
        setSuccessMessage("Image deleted successfully");
        let updatedData = broomerImages.filter((item, key) => {
            if (item.name !== file.name) {
                return item;
            }
        })
        if (updatedData.length === 0) {
            formik.setFieldValue('files', []);
        } else {
            formik.setFieldValue('files', updatedData);
        }
        setColor("success")
    }


}


