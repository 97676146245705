import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../MonthlyPrint.css'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const MonthlyPrint = React.forwardRef(
    ({ tractorMonthlyData }, ref) => {

        const totalSumOfAmount = tractorMonthlyData
            ? tractorMonthlyData.reduce((sum, row) => sum + (row.amount || 0), 0)
            : 0;

        const totalSumOfDiesel = tractorMonthlyData
            ? tractorMonthlyData.reduce((sum, row) => sum + (row.diesel || 0), 0)
            : 0;
            const totalSumOfReding = tractorMonthlyData
            ? tractorMonthlyData.reduce((sum, row) => sum + (row.totalreading || 0), 0)
            : 0;
        return (
            <>{tractorMonthlyData &&
                <div
                    ref={ref} className="hideOnScreen"
                >
                    <div style={{ textAlign: "center", fontSize: "24px", backgroundColor: "#6699CC", color: "#fff", padding: "2px" }} ><b>Tractor Monthly Report</b></div>
                    <TableContainer component={Paper}>

                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Id</b></TableCell>
                                    <TableCell><b>Date</b></TableCell>
                                    <TableCell><b>Start Reading</b></TableCell>
                                    <TableCell><b>End Reading</b></TableCell>
                                    <TableCell><b>Total Reading</b></TableCell>
                                    <TableCell><b>From Location</b></TableCell>
                                    <TableCell align="right"><b>To Location</b></TableCell>
                                    <TableCell align="right"><b>Diesel</b></TableCell>
                                    <TableCell align="right"><b>Distance</b></TableCell>
                                    <TableCell align="right"><b>Rate</b></TableCell>
                                    <TableCell align="right"><b>Amount</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tractorMonthlyData && [...tractorMonthlyData].reverse().map((row, key) => (
                                    row?.holiday == 1 ? <>
                                        <TableRow
                                            key={key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: "red", background: "yellow" }}
                                        >
                                            <TableCell component="th" scope="row" style={{ color: "red" }}>
                                                {key + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ color: "red" }}>
                                                {row?.date}-{row?.month}-{row?.year}
                                            </TableCell>
                                            <TableCell component="th" scope="row">

                                            </TableCell>
                                            <TableCell component="th" scope="row">

                                            </TableCell>
                                            <TableCell component="th" scope="row">

                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ color: "red" }}>
                                                {row?.reason}
                                            </TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </> :
                                        <TableRow
                                            key={key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {key + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.date}-{row?.month}-{row?.year}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.startreading}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.endreading}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.totalreading}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.fromlocation}
                                            </TableCell>
                                            <TableCell align="right">{row?.tolocation}</TableCell>
                                            <TableCell align="right">{row?.diesel}</TableCell>
                                            <TableCell align="right">{row?.distance}</TableCell>
                                            <TableCell align="right">{row?.rate}</TableCell>
                                            <TableCell align="right">{row?.amount}</TableCell>
                                        </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ flexGrow: 1 }}>
                        <hr />
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                Total Reading
                            </Grid>
                            <Grid item xs={1}>
                                :
                            </Grid>
                            <Grid item xs={6}>
                                {totalSumOfReding}
                            </Grid>
                            <Grid item xs={5}>
                                Total Diesel
                            </Grid>
                            <Grid item xs={1}>
                                :
                            </Grid>
                            <Grid item xs={6}>
                                {totalSumOfDiesel}
                            </Grid>
                            <Grid item xs={5}>
                                Amount
                            </Grid>
                            <Grid item xs={1}>
                                :
                            </Grid>
                            <Grid item xs={6}>
                                {totalSumOfAmount.toFixed(2)}
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            }
            </>
        );
    })
export default MonthlyPrint;
