import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {  useFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Form, Col } from "react-bootstrap";
import Stack from '@mui/material/Stack';
import { Button } from "@mui/material";


export default function TankerSearchModule({ tankerData, setTankerId, setMonth, setYear
}) {
    const formik = useFormik({
        initialValues: {
            tankerid: 0,
            month: 0,
            year: 0
        },
        validationSchema: Yup.object({
            tankerid: Yup.number()
                .positive('Please Select Water Tanker')
                .required('Please Select Water Tanker'),
            month: Yup.number()
                .positive('Please Select Month')
                .required('Please Select Month'),
            year: Yup.number()
                .positive('Please Select Year')
                .required('Please Select Year'),
        }),
        onSubmit: async (values) => {
            setTankerId(values.tankerid)
            setMonth(values.month)
            setYear(values.year)
        }
    });

    return (
        <>
            <Container>
                <Row >

                    <Form onSubmit={formik.handleSubmit}>

                        <Col xs={12} md={8} lg={8} xl={8} className="shadow p-3 mb-5 bg-white rounded">
                            <div style={{ textAlign: "center", fontSize: "24px", backgroundColor: "#6699CC", color: "#fff", padding: "2px" }} ><b>Tanker Monthly Data List</b></div>
                            <Form.Group controlId="tankerid">
                                <Form.Label>Select Water Tanker  </Form.Label>
                                <select
                                    className="form-control"
                                    name="tankerid"
                                    value={formik.values.tankerid}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Select Water Tanker</option>
                                    {tankerData &&
                                        tankerData.map((index) => {
                                            return (
                                                <option value={index.id}>
                                                    {index.vehicleno}
                                                </option>
                                            );
                                        })}
                                </select>

                                {formik.touched.tankerid && formik.errors.tankerid && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.tankerid}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="month">

                                <Form.Label>Select Month</Form.Label>
                                <select
                                    className="form-control"
                                    name="month"
                                    value={formik.values.month}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Please Select Month</option>
                                    <option value={1}>January</option>
                                    <option value={2}>February</option>
                                    <option value={3}>March</option>
                                    <option value={4}>April</option>
                                    <option value={5}>May</option>
                                    <option value={6}>June</option>
                                    <option value={7}>July</option>
                                    <option value={8}>August</option>
                                    <option value={9}>September</option>
                                    <option value={10}>October</option>
                                    <option value={11}>November</option>
                                    <option value={12}>December</option>

                                </select>
                                {formik.touched.month && formik.errors.month && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.month}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="year">

                                <Form.Label>Select Year</Form.Label>
                                <select
                                    className="form-control"
                                    name="year"
                                    value={formik.values.year}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Please Select Year</option>
                                    <option value={2023}>2023</option>
                                    <option value={2024}>2024</option>
                                    <option value={2025}>2025</option>
                                </select>  {formik.touched.year && formik.errors.year && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.year}</div>
                                )}
                            </Form.Group>

                            <br />
                            <Stack spacing={2} direction="row" justifyContent="center" >
                                <Button variant="contained"
                                    type="submit"
                                >Submit</Button>
                            </Stack>
                        </Col>

                    </Form>
                </Row>
            </Container>

        </>
    );

}


