import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const serverPath = process.env.REACT_APP_SERVER_PROJPATH;
export default function API() {
  const getToken = () => {
    const sessionToken=sessionStorage.getItem('token');
    let token=JSON.stringify(sessionToken);
    if(token && token!=null && token!=undefined){
      let result=token && token.split('"');
      token=result&& result[2];
      result=token && token.split("\\");
      token=result&& result[0];
    }
    
    return token;
  }
  const navigate=useNavigate();
  const [token, setToken] = useState(getToken);
  const [user, setUser] = useState();

  const saveToken = (user, token) => {
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('token', JSON.stringify(token));
    setToken(token);
    setUser(user);
  }

  const getUser = () => {
    const sessionUser=sessionStorage.getItem('user');
    let user=(JSON.stringify(sessionUser));
    //  user=JSON.parse(user)
    //  user=JSON.parse(user)
    return user;
  }
  const logout = () => {
    sessionStorage.clear();
    navigate('/');
  }
  const http = axios.create({
    baseURL: serverPath,
    headers: {
      'Content-type': 'application/json',
      'Authorization':`Bearer ${token}`
    },
      // withCredentials:true,
  });
  return {
    http,
    saveToken,
    getToken,
    getUser,
    user,
    token,
    logout
  }
}
