import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Form, Col } from "react-bootstrap";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/joy/Snackbar';
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import API from "../../API";
import AdminLandingPage from "../../LayoutAdmin/AdminLandingPage";


export default function Registration() {
    const [tendorLocationData, setTendorLocationData] = useState();
    const { http, saveToken } = API();
    const [userData, setUserData] = useState();
    const [successMessage, setSuccessMessage] = useState(null);
    const [color, setColor] = React.useState('neutral');
    const [open, setOpen] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            location: 0,
            name: "",
            address: "",
            phone: "",
            role: 0,
            password: "",
            c_password: "",
            email: "",
        },
        validationSchema: Yup.object({
            location: Yup.number()
                .positive('Please Select Location')
                .required('Please Select Location'),

            role: Yup.number()
                .positive('Please Select Role')
                .required('Please Select Role'),
            name: Yup.string().required('Please Enter Name'),
            phone: Yup.string().required('Please Enter Phone Number'),
            address: Yup.string().required('Please Enter Address'),
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .required('Password is required'),
            c_password: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required'),
        }),
        onSubmit: async (values, { resetForm }) => {

            let fd = new FormData();
            fd.append("location", values.location);
            fd.append("role", values.role);
            fd.append("name", (values.name));
            fd.append("phone", (values.phone));
            fd.append("address", (values.address));
            fd.append("password", (values.password));
            fd.append("email", (values.email));
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            await http.post('user', fd, config
            ).then((res) => {
                if (res.data.status == "success") {
                    setSuccessMessage(res.data.message);
                    setColor("success")
                } else {
                    setSuccessMessage(res.data.message);
                    setColor("warning")
                }
                setOpen(true)
            }).catch((error) => {
                setSuccessMessage("Error Something is bad...");
                setColor("danger")
                setOpen(true)
            })


            resetForm();
        }
    });

    useEffect(() => {
        getUserRole();
        getLocation()
    }, [])




    return (
        <>
            <AdminLandingPage />
            <Container>
                <Row >

                    <Form onSubmit={formik.handleSubmit}>

                        <Col xs={12} md={8} lg={8} xl={8} className="shadow p-3 mb-5 bg-white rounded">
                            <div style={{ textAlign: "center", fontSize: "24px", backgroundColor: "#6699CC", color: "#fff", padding: "2px" }} ><b>Employee Registration Form</b></div>
                            <div>
                                <hr />
                            </div>

                            <Form.Group controlId="name">
                                <Form.Label>Employee Name</Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.name}</div>
                                )}
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    name="email"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.email}</div>
                                )}
                            </Form.Group>


                            <Form.Group controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    name="address"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                />
                                {formik.touched.address && formik.errors.address && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.address}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    name="phone"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.phone}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="role">
                                <Form.Label>Employee Role</Form.Label>
                                <select
                                    className="form-control"
                                    name="role"
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Select Employee Role</option>
                                    {userData &&
                                        userData.map((index, key) => {
                                            return (
                                                <option value={index.id} key={key}>
                                                    {index.role}
                                                </option>
                                            );
                                        })}
                                </select>
                                {formik.touched.role && formik.errors.role && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.role}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="location">

                                <Form.Label>Location</Form.Label>
                                <select
                                    className="form-control"
                                    name="location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Select Employee Location</option>
                                    {tendorLocationData &&
                                        tendorLocationData.map((index, key) => {
                                            return (
                                                <option value={index.id} key={key}>
                                                    {index.name}
                                                </option>
                                            );
                                        })}
                                </select>
                                {formik.touched.location && formik.errors.location && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.location}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.password}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="c_password">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    name="c_password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.c_password}
                                />
                                {formik.touched.c_password && formik.errors.c_password && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.c_password}</div>
                                )}
                            </Form.Group>

                            <br />
                            <Stack spacing={2} direction="row" justifyContent="center" >
                                <Button variant="contained"
                                    type="submit"
                                // onClick={values => setFormState(values)}
                                >Submit</Button>
                            </Stack>
                        </Col>

                    </Form>
                </Row>
            </Container>

            <Container>
                <Stack spacing={1} alignItems="center" >
                    <Snackbar
                        className='col-6'
                        autoHideDuration={4000}
                        open={open}
                        variant={'outlined'}
                        color={color}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        {successMessage}
                    </Snackbar>
                </Stack>
            </Container>

        </>
    );

    async function getUserRole() {
        await http.get('tender').then((res) => {
            if (res.data.status == "success") {
                setTendorLocationData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage('Error Something is bad...');
            setColor("danger")
            setOpen(true)
        });
    }
    async function getLocation() {
        await http.get('employee/role', {
        }).then((res) => {
            if (res.data.status == "success") {
                setUserData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage('Error Something is bad...');
            setColor("danger")
            setOpen(true)
        });
    }

}


