import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Stack } from '@mui/material';
import Snackbar from '@mui/joy/Snackbar';
import TractorSearchModule from './TractorSearchModule';
import { useState } from 'react';
import { useEffect } from 'react';
import API from '../../../API';
import { useRef } from 'react';
import MonthlyPrint from './MonthlyPrint';
import { useReactToPrint } from 'react-to-print';
import DailyPrint from './DailyPrint';
import { Container } from 'react-bootstrap';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function TractorDataList() {
    const { http, saveToken } = API();
    const [tractorData, setTractorData] = useState();
    const [tractorImages, setTractorImages] = useState();
    const [userData, setUserData] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    const [tractorId, setTractorId] = useState();
    const componentRef = useRef();
    const componentdailyRef = useRef();
    const [tractorMonthlyData, setTractorMonthlyData] = useState();
    const [tractorDailyData, setTractorDailyData] = useState();
    const [successMessage, setSuccessMessage] = useState(null);
    const [color, setColor] = React.useState('neutral');
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        getTractorData();
        getUserData();
    }, [])

    useEffect(() => {
        if (tractorId && year && month) {
           getMonthlyDataById()
        }
    }, [tractorId, year, month])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const handlePrint1 = useReactToPrint({
        content: () => componentdailyRef.current,
    });


    useEffect(() => {
        if (tractorDailyData && tractorImages) {
            handlePrint1();
        }
    }, [tractorDailyData, tractorImages])


    return (
        <>
            <TractorSearchModule tractorData={tractorData} setTractorId={setTractorId} setMonth={setMonth} setYear={setYear} />
            <br />
            <TableContainer component={Paper}>

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Id</b></TableCell>
                            <TableCell><b>Date</b></TableCell>
                            <TableCell><b>Start Reading</b></TableCell>
                            <TableCell><b>End Reading</b></TableCell>
                            <TableCell><b>Total Reading</b></TableCell>
                            <TableCell><b>From Location</b></TableCell>
                            <TableCell align="right"><b>To Location</b></TableCell>
                            <TableCell align="right"><b>Diesel</b></TableCell>
                            <TableCell align="right"><b>Distance</b></TableCell>
                            <TableCell align="right"><b>Holiday</b></TableCell>
                            <TableCell align="right"><b>Download</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tractorMonthlyData && tractorMonthlyData.map((row, key) => (
                            <TableRow
                                key={key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {key + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.date}-{row?.month}-{row?.year}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.startreading}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.endreading}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.totalreading}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.fromlocation}
                                </TableCell>
                                <TableCell align="right">{row?.tolocation}</TableCell>
                                <TableCell align="right">{row?.diesel}</TableCell>
                                <TableCell align="right">{row?.distance}</TableCell>
                                <TableCell align="right">{row?.holiday == 1 ? "YES" : "NO"}</TableCell>

                                <TableCell align="right">
                                    <Button variant="contained"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            getImagesById(row);
                                        }
                                        }
                                    >Print</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {tractorMonthlyData && <>
                <Stack spacing={2} direction="row" justifyContent="center" >
                    <Button variant="contained"
                        onClick={(e) => {
                            e.preventDefault();
                            handlePrint();
                        }
                        }
                    >Print</Button>
                </Stack>
                <MonthlyPrint tractorMonthlyData={tractorMonthlyData} ref={componentRef} />
            </>}


            {
                tractorImages &&
                <DailyPrint
                    tractorDailyData={tractorDailyData}
                    ref={componentdailyRef}
                    tractorImages={tractorImages}
                    tractorData={tractorData}
                    userData={userData}
                />
            }
             <Container>
                <Stack spacing={1} alignItems="center" >
                    <Snackbar
                        className='col-6'
                        autoHideDuration={4000}
                        open={open}
                        variant={'outlined'}
                        color={color}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        {successMessage}
                    </Snackbar>
                </Stack>
            </Container>
        </>
    );
    async function getTractorData() {
        await http.get('tractormaster').then((res) => {
            if (res.data.status == "success") {
                setTractorData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }
    async function getUserData() {
        await http.get('user/role', {
            params: {
                role: 2
            }
        }).then((res) => {
            if (res.data.status == "success") {
                setUserData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }

    async function getMonthlyDataById(){
        await http.get('tractor/checkin/monthly', {
            params: {
                tractorid: tractorId, year: year, month: month
            }
        }).then((res) => {
            if (res.data.status == "success") {
                let data = (res.data.data);
                if (data.length > 0) {
                    setTractorMonthlyData(JSON.parse(res.data.data))
                    setSuccessMessage(res.data.message);
                    setColor("success")
                    setOpen(true)
                } else {
                    setTractorMonthlyData()
                    setSuccessMessage("No Tractor Data Found");
                    setColor("success")
                    setOpen(true)
                }
            } else {
                setTractorMonthlyData()
                setSuccessMessage("No Tractor Data Found");
                setColor("success")
                setOpen(true)
            }

        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }
    async function getImagesById(data) {
        setTractorDailyData(data)
        await http.get('tractor_image/checkin/id', {
            params: {
                id: data.id,
            },
        }).then((res) => {
            if(res.data.status=="success"){
                setTractorImages(JSON.parse(res.data.data));
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });

    }


}