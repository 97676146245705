import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Form, Col } from "react-bootstrap";
import AdminLandingPage from "../../../LayoutAdmin/AdminLandingPage";
import API from "../../../API";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/joy/Snackbar';
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';


export default function JCBHolidayEntry() {
    const [JCBData, setJCBData] = useState();

    const { http } = API();
    const [successMessage, setSuccessMessage] = useState(null);
    const [color, setColor] = React.useState('neutral');
    const [open, setOpen] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            id: 0,
            jcbid: 0,
            date: 0,
            month: 0,
            year: 0,
            reason: ""
        },
        validationSchema: Yup.object({
            jcbid: Yup.number()
                .positive('Please Select JCB')
                .required('Please Select JCB'),
            date: Yup.number()
                .positive('Please Select Month')
                .required('Please Select Month'),
            month: Yup.number()
                .positive('Please Select Month')
                .required('Please Select Month'),
            year: Yup.number()
                .positive('Please Select Year')
                .required('Please Select Year'),
            reason: Yup.string()
                .required('Holiday Reason is Required'),
        }),
        onSubmit: async (values, { resetForm }) => {

            let fd = new FormData();
            fd.append("jcbid", values.jcbid);
            fd.append("date", values.date);
            fd.append("year", values.year);
            fd.append("month", values.month);
            fd.append('reason', values.reason);
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            await http.post(`jcb/checkin/holiday`, fd, config
            ).then((res) => {
                if (res.data.status == "success") {
                    setSuccessMessage(res.data.message);
                    setColor("success")
                } else {
                    setSuccessMessage(res.data.message);
                    setColor("warning")
                }
                setOpen(true)
            }).catch((error) => {
                setSuccessMessage("Error Something is bad...");
                setColor("danger")
                setOpen(true)
            })

            resetForm();
        }
    });

    useEffect(() => {
        getJCBData();

    }, [])

    return (
        <>
            <Container>
                <Row >

                    <Form onSubmit={formik.handleSubmit}>

                        <Col xs={12} md={8} lg={8} xl={8} className="shadow p-3 mb-5 bg-white rounded">
                            <div style={{ textAlign: "center", fontSize: "24px", backgroundColor: "#6699CC", color: "#fff", padding: "2px" }} ><b>JCB Holiday Form</b></div>
                            <div>
                                <hr />
                            </div>
                            <Form.Group controlId="jcbid">
                                <Form.Label>Select JCB </Form.Label>
                                <select
                                    className="form-control"
                                    name="jcbid"
                                    value={formik.values.jcbid}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Select JCB</option>
                                    {JCBData &&
                                        JCBData.map((index, key) => {
                                            return (
                                                <option value={index.id} key={key}>
                                                    {index.vehicleno}
                                                </option>
                                            );
                                        })}
                                </select>
                                {formik.touched.jcbid && formik.errors.jcbid && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.jcbid}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="date">

                                <Form.Label>Select Date</Form.Label>
                                <select
                                    className="form-control"
                                    name="date"
                                    value={formik.values.date}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Please Select Date</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={5}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                    <option value={11}>11</option>
                                    <option value={12}>12</option>
                                    <option value={13}>13</option>
                                    <option value={14}>14</option>
                                    <option value={15}>15</option>
                                    <option value={16}>16</option>
                                    <option value={17}>17</option>
                                    <option value={18}>18</option>
                                    <option value={19}>19</option>
                                    <option value={20}>20</option>
                                    <option value={21}>21</option>
                                    <option value={22}>22</option>
                                    <option value={23}>23</option>
                                    <option value={24}>24</option>
                                    <option value={25}>25</option>
                                    <option value={26}>26</option>
                                    <option value={27}>27</option>
                                    <option value={28}>28</option>
                                    <option value={29}>29</option>
                                    <option value={30}>30</option>
                                    <option value={31}>31</option>
                                </select>
                                {formik.touched.date && formik.errors.date && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.date}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="month">

                                <Form.Label>Select Month</Form.Label>
                                <select
                                    className="form-control"
                                    name="month"
                                    value={formik.values.month}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Please Select Month</option>
                                    <option value={1}>January</option>
                                    <option value={2}>February</option>
                                    <option value={3}>March</option>
                                    <option value={4}>April</option>
                                    <option value={5}>May</option>
                                    <option value={6}>June</option>
                                    <option value={7}>July</option>
                                    <option value={8}>August</option>
                                    <option value={9}>September</option>
                                    <option value={10}>October</option>
                                    <option value={11}>November</option>
                                    <option value={12}>December</option>

                                </select>
                                {formik.touched.month && formik.errors.month && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.month}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="year">

                                <Form.Label>Select Year</Form.Label>
                                <select
                                    className="form-control"
                                    name="year"
                                    value={formik.values.year}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0}>Please Select Year</option>
                                    <option value={2023}>2023</option>
                                    <option value={2024}>2024</option>
                                    <option value={2025}>2025</option>
                                </select>
                                {formik.touched.year && formik.errors.year && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.year}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="reason">
                                <Form.Label>Holiday Reason</Form.Label>
                                <Form.Control
                                    name="reason"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.reason}
                                />
                                {formik.touched.reason && formik.errors.reason && (
                                    <div style={{ color: "red", paddingLeft: "5px" }}>{formik.errors.reason}</div>
                                )}
                            </Form.Group>



                            <br />
                            <Stack spacing={2} direction="row" justifyContent="center" >
                                <Button variant="contained"
                                    type="submit"
                                >Submit</Button>
                            </Stack>
                        </Col>

                    </Form>
                </Row>
            </Container>

            <Container>
                <Stack spacing={1} alignItems="center" >
                    <Snackbar
                        className='col-6'
                        autoHideDuration={4000}
                        open={open}
                        variant={'outlined'}
                        color={color}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        {successMessage}
                    </Snackbar>
                </Stack>
            </Container>

        </>
    );

    async function getJCBData() {
        await http.get('jcbmaster').then((res) => {
            if (res.data.status == "success") {
                setJCBData(JSON.parse(res.data.data))
            }
        }).catch((error) => {
            setSuccessMessage("Error Something is bad...");
            setColor("danger")
            setOpen(true)
        });
    }




}


