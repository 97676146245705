import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import '../MonthlyPrint.css'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const DailyPrint = React.forwardRef(
    ({ tipperDailyData, tipperImages, tipperData, userData }, ref) => {

        let driverData=userData?.filter((item,key)=>{
            if(tipperDailyData?.driver_id==item?.id){
                return item;
            }
        })

        let vehicleData=tipperData?.filter((item,key)=>{
            if(tipperDailyData?.tipper_id==item?.id){
                return item;
            }
        })
        const imageUrl = process.env.REACT_APP_SERVER_PROJPATH;
        return (
            <div
                ref={ref} className="hideOnScreen"
            >{tipperDailyData&&
                <Box sx={{ flexGrow: 1 }}>
                    <div style={{ textAlign: "center", fontSize: "24px", backgroundColor: "#6699CC", color: "#fff", padding: "2px" }} ><b>Tipper ly Report</b></div>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            Tempo Name
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {vehicleData && vehicleData[0]?.vehicleno}
                        </Grid>

                        <Grid item xs={5}>
                            Driver Name
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                            {driverData && driverData[0]?.name}
                        </Grid>

                        <Grid item xs={5}>
                            Date
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {tipperDailyData?.date}-{tipperDailyData?.month}-{tipperDailyData?.year}
                        </Grid>

                        <Grid item xs={5}>
                            Start Reading
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {tipperDailyData?.startreading}
                        </Grid>

                        <Grid item xs={5}>
                            End Reading
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {tipperDailyData?.endreading}
                        </Grid>

                        <Grid item xs={5}>
                            Total Reading
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {tipperDailyData?.totalreading}
                        </Grid>

                        <Grid item xs={5}>
                            From Location
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {tipperDailyData?.fromlocation}
                        </Grid>

                        <Grid item xs={5}>
                            To Location
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {tipperDailyData?.tolocation}
                        </Grid>

                        <Grid item xs={5}>
                            Diesel in Ltr
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {tipperDailyData?.diesel}
                        </Grid>

                        <Grid item xs={5}>
                            Diesel Rate
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {tipperDailyData?.rate}
                        </Grid>

                        <Grid item xs={5}>
                            Distance
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {tipperDailyData?.distance}
                        </Grid>

                        <Grid item xs={5}>
                            Amount
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        {tipperDailyData?.amount}
                        </Grid>

                        <Grid item xs={5}>
                            Images
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                        {tipperImages && tipperImages.length > 0 && (
                            <div>
                                <Grid item xs={2}>

                                </Grid>
                                <Grid item xs={8}>
                                    {tipperImages.map((file, index) => (
                                        <div className="shadow p-3 mb-2 bg-white rounded">
                                            <img
                                                key={index}
                                                src={imageUrl + file.image}
                                                alt={`Image ${index + 1}`}
                                                style={{ width: '90%', maxHeight: '450px', marginRight: '10px' }}
                                            />
                                        </div>
                                    ))}
                                </Grid>
                                <Grid item xs={2}>

                                </Grid>
                            </div>
                        )}
                    </Grid>
                </Box>}
            </div>
        );
    }
)
export default DailyPrint;